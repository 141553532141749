<template>
  <div id="cat-admin">
    <vc-app-header />

    <h1 class="text-center mb-4">
      <strong>Category Administration</strong>
    </h1>

    <div
      v-if="$store.state.currentUser && $store.state.currentUser.systemAdmin"
      class="content"
    >
      <b-row class="mb-3">
        <b-col class="mb-3 mb-sm-0">
          <b-input
            type="search"
            placeholder="Search Categories"
            v-model="query"
          />
        </b-col>
        <b-col sm="3" md="3" class="text-center mb-3 mb-sm-0">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="viewMode"
            :options="['Active', 'Inactive', 'All']"
            name="radios-btn-default"
            buttons
            button-variant="light"
          ></b-form-radio-group>
        </b-col>
        <b-col sm="3" md="3">
          <b-button block variant="dark" @click="add">
            <strong>Add Category</strong>
          </b-button>
        </b-col>
      </b-row>

      <vc-editor-table
        v-if="filteredCategories && filteredCategories.length > 0"
        :items="filteredCategories"
        item-name="Category"
        title-field="name"
        :can-delete="false"
        :sortable="false"
        @edit="edit"
      />
    </div>
    <h5 v-else class="mt-4 text-center text-muted">
      You do not have permissions to manage categories.
    </h5>

    <b-modal
      id="cat-modal"
      no-fade
      centered
      :title="modalModeLabel"
      :ok-disabled="!modalMeetsRequirements"
      :ok-title="modalModeLabel"
      cancel-variant="link"
      @hide="selectedCategory = undefined"
      @ok="save"
    >
      <b-form-group label="Category Name">
        <b-input type="text" v-model="modalName" />
      </b-form-group>

      <b-form-group label="Category Key">
        <b-input
          type="text"
          v-model="modalKey"
          :disabled="!!selectedCategory"
        />
        <p class="mb-0">
          <small class="text-muted">
            Used for the URL of the category.
          </small>
        </p>
      </b-form-group>

      <b-form-group label="Icon Style">
        <b-input type="text" v-model="modalIconStyle" placeholder="fas" />
        <p class="mb-0">
          <small class="text-muted">
            The Font Awesome icon style to use. Solid icons are used by default.
          </small>
        </p>
      </b-form-group>

      <b-form-group label="Icon">
        <template #label>
          Icon
          <font-awesome-icon
            v-if="modalIcon"
            :icon="[modalIconStyle, modalIcon]"
            class="ml-1"
          />
        </template>
        <b-input type="text" v-model="modalIcon" />
        <p class="mb-0">
          <small class="text-muted">
            The Font Awesome icon to use.
          </small>
        </p>
      </b-form-group>

      <b-form-group label="Keywords">
        <b-form-tags
          placeholder="Add Keyword..."
          v-model="modalKeywords"
          tag-variant="primary"
          add-button-variant="dark"
          separator=","
        />
        <p class="mb-0">
          <small class="text-muted">
            Used to improve search results.
          </small>
        </p>
      </b-form-group>

      <b-check switch v-model="modalActive">Active</b-check>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CategoryAdmin",
  data() {
    return {
      query: "",
      viewMode: "Active",
      selectedCategory: undefined,
      modalActive: false,
      modalIcon: undefined,
      modalName: undefined,
      modalKey: undefined,
      modalIconStyle: undefined,
      modalKeywords: []
    };
  },
  watch: {
    modalName() {
      if (this.selectedCategory) return;
      this.modalKey = (this.modalName ?? "")
        .toLowerCase()
        .replace(/[\W_]+/g, "-");
    },
    modalKey() {
      if (this.selectedCategory) return;
      this.$nextTick(() => {
        this.modalKey = (this.modalKey ?? "")
          .toLowerCase()
          .replace(/[\W_]+/g, "-");
      });
    },
    selectedCategory() {
      this.modalName = this.selectedCategory?.name;
      this.modalKey = this.selectedCategory?.key;
      this.modalIcon = this.selectedCategory?.icon;
      this.modalIconStyle = this.selectedCategory?.iconStyle;
      this.modalActive = this.selectedCategory?.active;
      this.modalKeywords = this.selectedCategory?.keywords ?? [];
    }
  },
  computed: {
    filteredCategories() {
      return (this.$store.state.categories ?? []).filter(
        v =>
          v.name.toLowerCase().includes(this.query.toLowerCase()) &&
          (this.viewMode == "All" ||
            (this.viewMode == "Active" && v.active) ||
            (this.viewMode == "Inactive" && !v.active))
      );
    },
    modalModeLabel() {
      return `${!this.selectedCategory ? "Add" : "Edit"} Category`;
    },
    modalMeetsRequirements() {
      return this.modalIcon && this.modalKey && this.modalName;
    }
  },
  methods: {
    add() {
      this.selectedCategory = undefined;
      this.modalName = this.modalKey = this.modalIconStyle = this.modalIcon = undefined;
      this.modalKeywords = [];
      this.modalActive = false;
      this.$bvModal.show("cat-modal");
    },
    edit(i) {
      this.selectedCategory = i;
      this.$bvModal.show("cat-modal");
    },
    save() {
      const data = {
        key: this.modalKey,
        name: this.modalName,
        iconStyle: this.modalIconStyle,
        icon: this.modalIcon,
        keywords: this.modalKeywords,
        active: this.modalActive,
        isNew: !this.selectedCategory
      };
      this.$store.dispatch("pushCategoryChanges", data).then(
        () => {},
        e => {
          this.$bvModal.msgBoxOk(e.message, {
            title: "Error",
            centered: true,
            noFade: true
          });
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
#cat-admin .content ::v-deep {
  .btn-outline-primary,
  .btn-outline-danger {
    border: 0;
  }

  div.actions {
    border-radius: 15px;

    .btn {
      border-radius: 15px;
    }
  }
}
</style>